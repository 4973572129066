import React, { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

// icons
import { PiExport } from "react-icons/pi";
import { TfiExport } from "react-icons/tfi";
import { FaChildren, FaPerson } from "react-icons/fa6";
import { FaShieldAlt, FaDog } from "react-icons/fa";
import { TbHomeDown, TbHomeMove } from "react-icons/tb";
import { IoIosContact } from "react-icons/io";

import logo from "../logo/logo.png";
import { useGridApiContext } from '@mui/x-data-grid';

// Import the json data for language translation
import translateData from '../language/de.json'

// material theme
import { useTheme } from '@mui/material/styles';
import CalendarComponent from './CalendarComponent';

// PDF columns configuration
const columnsPdf = [
  { field: 'Booking_No' },
  { field: 'Name' },
  { field: 'Arrival' },
  { field: 'Departure' },
  { field: 'Adult' },
  { field: 'Children' },
  { field: 'Pets' },
  { field: 'Insurance' },
];

// Translate the columns for the PDF
const translatedColumns = columnsPdf.map(col => ({
  ...col,
  header: translateData.columnTranslations[col.field] || col.field,
  dataKey: col.field,
}));

// Function to format date as DD-MM-YYYY
function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
}

// Translate booking name if needed
const translateBookingName = (value) => {
  if (value === "Eigene Buchung") {
    return translateData.ownBooking;
  }
  return value;
};

// PDF export function with filtering for current year dates
const exportPdf = (bookings, propertyToSave) => {
  const doc = new jsPDF();

  if (propertyToSave in bookings) {
    const currentYear = new Date().getFullYear();
    // Filter bookings to only include those with arrival and departure in the current year
    const bookingsArray = bookings[propertyToSave].filter(booking => {
      const arrivalYear = new Date(booking.Arrival).getFullYear();
      const departureYear = new Date(booking.Departure).getFullYear();
      return arrivalYear === currentYear && departureYear === currentYear;
    });

    if (bookingsArray.length === 0) {
      return; // No bookings for the selected property in the current year, do nothing
    }

    const rows = bookingsArray.map(booking =>
      columnsPdf.reduce((acc, colDef) => {
        if (colDef.field === 'Name') {
          acc[colDef.field] = translateBookingName(booking[colDef.field]);
        } else if (colDef.field === 'Arrival' || colDef.field === 'Departure') {
          acc[colDef.field] = formatDate(booking[colDef.field]);
        } else {
          acc[colDef.field] = booking[colDef.field];
        }
        return acc;
      }, {})
    );

    doc.autoTable({
      columns: translatedColumns,
      body: rows,
      theme: 'striped',
      startY: 20,
    });

    doc.text(propertyToSave, 16, 13);

    const insuranceMessage = `${translateData.insurance.title}:\nY - ${translateData.insurance.positive}\nN - ${translateData.insurance.negative}`;
    let finalY = doc.lastAutoTable.finalY || 10;
    finalY += 10;
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(insuranceMessage, 15, finalY + 6);

    const companyName = `© 22 Estates GmbH 2015 - ${new Date().getFullYear()}`;
    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextDimensions(companyName).w;
      const xPos = (pageWidth - textWidth) / 2;
      const yPos = doc.internal.pageSize.height - 10;
      doc.text(companyName, xPos, yPos);
      doc.addImage(logo, 'PNG', xPos + 7, doc.internal.pageSize.height - 35, 40, 20);
    }
    doc.save(`${propertyToSave}-bookings-export.pdf`);
  }
};

const legendItems = [
  { label: `${translateData.insurance.positive}`, color: 'rgba(0, 255, 0, .7)' },
  { label: `${translateData.insurance.negative}`, color: 'rgba(255, 0, 0, .7)' },
];

const Legend = () => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', gap: 2, p: 1, border: '1px solid grey', borderRadius: 3, marginBottom: 10,
  }}>
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 1,
      flexDirection: { xs: 'column', sm: 'row' },
      '& svg': {
        fontSize: { xs: '20px', sm: '30px' },
      }
    }}>
      <FaShieldAlt />
      <Typography> {translateData.insurance.title}</Typography>
    </Box>
    {legendItems.map((item) => (
      <Box key={item.label} sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 1 }}>
        <Box sx={{ width: 20, height: 20, backgroundColor: item.color }} />
        <Typography>{item.label}</Typography>
      </Box>
    ))}
  </Box>
);

const getInsuranceStatusStyle = (status) => {
  return status === 'Y' ? { color: 'green' } : { color: 'red' };
};

// Toolbar factory remains unchanged except for passing data that’s already filtered in exportPdf
function CustomToolbarFactory(data, exportFunction, propertyName) {
  return function CustomToolbar(someData) {
    const sortDataByArrival = (dataArray) => {
      return dataArray.sort((a, b) => new Date(a.Arrival) - new Date(b.Arrival));
    };
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <Button
          startIcon={<PiExport />}
          size='small'
          color="primary"
          onClick={() => {
            const sortedData = {};
            for (const property in data) {
              if (data.hasOwnProperty(property)) {
                sortedData[property] = sortDataByArrival(data[property]);
              }
            }
            exportFunction(sortedData, propertyName);
          }}
        >
          Export PDF
        </Button>
      </GridToolbarContainer>
    );
  }
}

const transformedData = [];
const isSmallScreen = window.innerWidth < 600;

export default function PropertyTable({ bookings }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const maxScroll = container.scrollWidth - container.clientWidth;
    let direction = 1;
    let position = 0;
    const step = () => {
      if (!container) return;
      if (position >= maxScroll || position <= 0) {
        direction *= -1;
      }
      position += direction * 2;
      container.scrollLeft = position;
      requestAnimationFrame(step);
    };
    const animationFrameId = requestAnimationFrame(step);
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  const columns = [
    { 
      field: 'Name', 
      headerName: <span className="iconProperty"><IoIosContact /></span>, 
      sortable: false,
      flex: 1, 
      minWidth: 180
    },
    { field: 'Arrival', headerName: 'Arrival', sortable: false, hide: true },
    {
      field: 'formattedArrival',
      headerName: <span className="iconProperty"><TbHomeDown /></span>,
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'formattedDeparture',
      headerName: <span className="iconProperty"><TbHomeMove /></span>,
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      hide: true,
    },
    {
      field: 'Adult', 
      headerName: <span className="iconProperty" style={{ marginLeft: "-5px" }}><FaPerson /></span>, 
      sortable: false,
      flex: 1,
      minWidth: 70
    },
    { 
      field: 'Children', 
      headerName: <span className="iconProperty"><FaChildren /></span>, 
      sortable: false,
      flex: 1,
      minWidth: 70
    },
    { 
      field: 'Pets', 
      headerName: <span className="iconProperty" style={{ marginLeft: "-1px" }}><FaDog /></span>, 
      sortable: false,
      flex: 1,
      minWidth: 70,
    },
    { 
      field: 'Insurance', 
      headerName: <span className="iconProperty"><FaShieldAlt /></span>, 
      sortable: false,
      headerAlign: 'center',
      flex: 1,
      minWidth: 70,
      renderCell: (params) => (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%', 
          height: '100%',
          backgroundColor: params.value === 'Y' ? 'rgba(0, 255, 0, .7)' : 'rgba(255, 0, 0, .7)',
        }}>
          {params.value}
        </div>
      ),
    },
  ];

  transformedData.length = 0;
  for (const propertyName in bookings) {
    bookings[propertyName].forEach(item => {
      transformedData.push({ ...item, Property: propertyName, id: item.Booking_No });
    });
  }

  return (
    <Box>
      {Object.entries(bookings).map(([propertyName, bookingsArray]) => {
        // Filter bookings to include only those with both Arrival and Departure in the current year
        const filteredBookings = bookingsArray.filter(booking => {
          const arrivalYear = new Date(booking.Arrival).getFullYear();
          const departureYear = new Date(booking.Departure).getFullYear();
          return arrivalYear === currentYear && departureYear === currentYear;
        });
        return (
          <div ref={containerRef} key={propertyName} className='dataGridContainer'>
            <div style={{ textAlign: "left" }}>
              <Typography variant="h6" sx={{ padding: 2, backgroundColor: "rgba(80, 80, 80, 0.8)", color: "white", borderRadius: "10px 10px 2px 2px" }}>
                {propertyName}
              </Typography>
            </div>
            <DataGrid
              rows={filteredBookings.map(booking => ({
                ...booking,
                Name: booking.Name === "Eigene Buchung" ? translateData.ownBooking : booking.Name,
                id: booking.Booking_No,
                Arrival: booking.Arrival,
                formattedArrival: formatDate(booking.Arrival),
                Departure: booking.Departure,
                formattedDeparture: formatDate(booking.Departure),
                status: booking.status,
              }))}
              columns={columns}
              getRowClassName={(params) => `status-${params.row.status}`}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    Arrival: false,
                    Departure: false,
                    status: false,
                  }
                },
              }}
              slots={{
                toolbar: CustomToolbarFactory(bookings, exportPdf, propertyName),
              }}
              sortModel={[
                { field: 'Arrival', sort: 'asc' },
              ]}
              sx={{
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                  width: '10px',
                  height: '10px',
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                  backgroundColor: 'darkgrey',
                  borderRadius: '10px',
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                  backgroundColor: 'white',
                  borderRadius: '10px',
                },
                '@media (max-width: 600px)': {
                  '& .MuiDataGrid-cell': {
                    animation: 'shakeCell 1s cubic-bezier(.36,.07,.19,.97) 1',
                  },
                  '@keyframes shakeCell': {
                    '0%, 100%': { transform: 'translateX(0)' },
                    '10%, 30%, 50%, 70%, 90%': { transform: 'translateX(3px)' },
                    '20%, 40%, 60%, 80%': { transform: 'translateX(-3px)' },
                  },
                },
                '& .status-1': {
                  position: 'relative',
                  backgroundColor: '#dff0d8',
                  color: 'black',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: '5px',
                    backgroundColor: 'green',
                    boxSizing: 'border-box',
                  },
                },
              }}
            />
            <Legend />
            <div className='calendarComponent' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CalendarComponent bookings={bookingsArray} className='calendarItem' />
            </div>
          </div>
        );
      })}
    </Box>
  );
}
